<template>
<div :class="$style.root">
  <div :class="$style.header">
    <div>
      <div v-if="name" :class="$style.title">{{ name }}</div>
      <div v-if="message" :class="$style.message">{{ message }}</div>
    </div>
    <VBtn v-if="!readonly" class="float-right" depressed small color="primary" :disabled="pending" @click="onEdit">
      <template v-if="pending">Идет загрузка файла</template>
      <template v-else>{{ empty ? 'Загрузить документ': 'Загрузить новую версию'}}</template>
    </VBtn>
  </div>
  <div v-if="!empty" :class="$style.table">
    <VDataTable :headers="headers" :items="files" :item-class="rowClassControl" :mobile-breakpoint="0" show-expand hide-default-footer>
      <template v-slot:item.end="{ item: { end } }">{{ end || '-'}}</template>
      <template v-slot:item.status="{ item: { status } }">{{ status || '-'}}</template>
      <template v-slot:item.history="{ item: { history } }">
        Версия {{ ( history.length || 0 ) + 1 }}
      </template>
      <template v-slot:item.actions="{ item }">
        <div class="text-right" >
          <VBtn v-if="item.value" class="ml-2" depressed x-small @click="() => onDownload({ id: item.value })">Скачать</VBtn>
          <VBtn v-else class="ml-2" depressed x-small disabled>В архиве</VBtn>
        </div>
      </template>
      <template v-slot:expanded-item="{ headers, item: { history } }">
        <td :colspan="headers.length" class="px-0">
          <div :class="$style.inner">
            <VDataTable :headers="headers" :items="history" :item-class="rowClassControl" :mobile-breakpoint="0" show-expand hide-default-header hide-default-footer>
              <template v-slot:item.end="{ item: { end } }">{{ end || '-'}}</template>
              <template v-slot:item.status="{ item: { status } }">{{ status || '-'}}</template>
              <template v-slot:item.history="{ index }">
                Версия {{ history.length - index }}
              </template>
            </VDataTable>
          </div>
        </td>
      </template>
    </VDataTable>
  </div>
</div>
</template>

<script>
import Api from '@/store/file/api';
import { get, head, isEmpty } from 'lodash-es';
export default {
  name: 'FileHistory',
  props: {
   id: { type: String },
   name: { type: String },
   readonly: { type: Boolean },
   required: { type: Boolean },
   files: { type: Array },
  },
  computed: {
    headers() {
      return [
        {text: 'Название файла', value: 'name', sortable: false},
        {text: 'Версия документа', value: 'history', sortable: false},
        {text: 'Дата загрузки', value: 'create', sortable: false},
        {text: 'Дата начала срока действия', value: 'date', sortable: false},
        {text: 'Дата окончания срока действия', value: 'end', sortable: false},
        {text: 'Статус', value: 'status', sortable: false},
        {value: 'actions', sortable: false}
      ];
    },
    current() {
      return head(this.files);
    },
    empty() {
      return isEmpty(this.files);
    },
    pending() {
      return get(this.current, 'pending');
    },
    message() {
      if (this.required && this.empty) return 'Обязателен для предоставления';
      if (!this.empty && !get(this.current, 'actual', false)) {
        if (get(this.current, 'end')) {
          const end = get(this.current, 'end').split('.').reverse().join('-');
          const now = new Date().toISOString().slice(0, 10);
          if (end < now) return 'Срок действия истек';
        }
        return 'С файлом проблемы, попробуйте загрузить новую версию';
      }
      return '';
    }
  },
  methods: {
    rowClassControl(item) {
      // console.log(item.history.length);
      if(item.message) return 'error lighten-5';
      if(item.history && item.history.length) return 'expand';
      return '';
    },
    onEdit() {
      this.$emit('edit', { id: this.id });
    },
    onDownload({ id }) {
      const { section, entity } = get(this.$route, ['meta', 'info']);
      return Api.downloadFile({ id, section, entity });
    },
  }
}
</script>

<style module lang="scss">
.root {
  border: 1px solid rgba(0, 0, 0, 0.12);
  margin-bottom: 20px;
}
.header {
  display: flex;
  justify-content: space-between;
  padding: 8px 16px;
  background: rgba(0, 0, 0, 0.12);
}
.title {
  font-size: 14px;
  font-weight: bold;
  align-self: center;
}
.footer {
  padding: 8px 16px;
}
.table, .inner {
  td {
    width: 170px;
    &:first-child {
      width: 1px;
      padding-right: 0px !important;
      :global(.v-data-table__expand-icon) {
        opacity: 0;
        pointer-events: none;
      }
    }
  }
  :global(.expand) {
    td {
      &:first-child {
        :global(.v-data-table__expand-icon) {
          opacity: 1;
          pointer-events: auto;
        }
      }
    }
  }
}
.inner {}
.message {
  font-size: 12px;
  color: red;
}
</style>
